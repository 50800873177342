<template>
  <div>
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Manage Performers !</h5>
      </b-row>         
    </div>
  
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="All Records" active>
          <b-row>
            <b-col sm="12" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
          </b-row>
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="SearchList"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
                <b-button variant="success" @click="DelUserProfile(data.item.seq_no)">E</b-button>
                <b-button variant="danger" @click="DelUserProfile(data.item.seq_no)">D</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New Record">
          <b-row class="text-center">
            <b-col class="12 my-4">
            <!-- <b-alert show variant="success"  v-if="MsgSuccess">Record created successfully!</b-alert>
            <b-alert show variant="danger"  v-if="MsgDanger">Error! Some Issue while create a new record!</b-alert>                  -->
              <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="profile_name" class="col-8">
                  <b-form-input
                    id="profile_name"
                    v-model="FormProf.profile_name"
                    placeholder="Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_title" class="col-8">
                  <b-form-input
                    id="profile_title"
                    v-model="FormProf.profile_title"
                    placeholder="Title"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group class="col-8">
                <b-form-textarea
                  id="Address"
                  size="md"
                  v-model="FormProf.profile_address"
                  placeholder="Address"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>   
                </b-form-group>               

                <b-form inline class="col-12">
                <b-form-group id="input-group-2" label-for="prof_country">
                  <b-form-input
                    id="prof_country"
                    v-model="FormProf.prof_country"
                    placeholder=" Country"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="prof_state" >
                  <b-form-input
                    id="prof_state"
                    v-model="FormProf.prof_state"
                    placeholder="State"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="prof_city">
                  <b-form-input
                    id="prof_city"
                    v-model="FormProf.prof_city"
                    placeholder=" City"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_cntct1">
                  <b-form-input
                    id="profile_cntct1"
                    v-model="FormProf.profile_cntct1"
                    placeholder=" Cntct1"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_cntct2">
                  <b-form-input
                    id="profile_cntct2"
                    v-model="FormProf.profile_cntct2"
                    placeholder="Cntct2"
                  ></b-form-input>                
                </b-form-group>
              </b-form>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_email" >
                  <b-form-input
                    id="profile_email"
                    v-model="FormProf.profile_email"
                    placeholder="Email"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_website">
                  <b-form-input
                    id="profile_website"
                    v-model="FormProf.profile_website"
                    placeholder="Website"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_facebook">
                  <b-form-input
                    id="profile_facebook"
                    v-model="FormProf.profile_facebook"
                    placeholder="Facebook"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_instagram">
                  <b-form-input
                    id="profile_instagram"
                    v-model="FormProf.profile_instagram"
                    placeholder="Instagram"
                  ></b-form-input>                
                </b-form-group>   

                <b-form-group id="input-group-2" label-for="profile_youtube">
                  <b-form-input
                    id="profile_youtube"
                    v-model="FormProf.profile_youtube"
                    placeholder="Youtube"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_twitter">
                  <b-form-input
                    id="profile_twitter"
                    v-model="FormProf.profile_twitter"
                    placeholder="Twitter"
                  ></b-form-input>                
                </b-form-group> 
              </b-form>

                <b-form-group id="input-group-2" label-for="profile_photo" class="col-8 mt-2">
                  <b-form-input
                    id="profile_photo"
                    v-model="FormProf.profile_photo"
                    placeholder="Photo"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="profile_description"
                  size="md"
                  v-model="FormProf.profile_description"
                  placeholder="Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <!-- Detail Section starts here >> users_profile_detail -->
                <b-form-textarea
                  id="profile_programs"
                  size="md"
                  v-model="FormProf.profile_programs"
                  placeholder="Programs"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_trainings"
                  size="md"
                  v-model="FormProf.profile_trainings"
                  placeholder="Trainings"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_participations"
                  size="md"
                  v-model="FormProf.profile_participations"
                  placeholder="Participations"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_awards"
                  size="md"
                  v-model="FormProf.profile_awards"
                  placeholder="Awards"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>                                               
                </b-form-group>                   

                <b-form-checkbox class="col-8 mt-2"
                  id="checkbox-1"
                  v-model="FormProf.premium_ind"
                  name="checkbox-1"
                  value="Y"
                  unchecked-value="N"
                >
                  Premium Profile?
                </b-form-checkbox>     

                <!-- Media Section starts here >> users_profile_media -->
                 <b-form-group  id="input-group-2" label-for="profile_photo1" class="col-8 mt-2">
 
                 <b-form-group label="Upload Images:" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="SelectedPhoto"
                    :options="Images"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>  
{{SelectedPhoto}}
                  <b-form-input
                    id="Video1"
                    v-model="FormProf.Video1"
                    placeholder="Video1"
                  ></b-form-input>
                  <b-form-input
                    id="Video2"
                    v-model="FormProf.Video2"
                    placeholder="Video2"
                  ></b-form-input>
                  <b-form-input
                    id="Brochure"
                    v-model="FormProf.Brochure"
                    placeholder="Brochure"
                  ></b-form-input>
                  <b-form-input
                    id="Biodata"
                    v-model="FormProf.Biodata"
                    placeholder="Biodata"
                  ></b-form-input>                                                                                    
                </b-form-group>              

                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Reset</b-button>
              </b-form>        
            </b-col>
          </b-row>          

        </b-tab>
        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Inactive Records">
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="ListInactive"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
                <b-button variant="success" @click="DelUser(data.item.seq_no)">E</b-button>
                <b-button variant="danger" @click="DelUser(data.item.seq_no)">D</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
    name: "App",
    data() {
      return {
      fields: [{key: 'seq_no',sortable: true}, "profile_name", "prof_city","prof_state","actions"],      
      perPage: 20,
      currentPage: 1,        
        FormProf: {
        profile_name: '',
        profile_title: '',
        profile_address: '',
        prof_country: '',
        prof_state: '',
        prof_city: '',
        profile_cntct1: '',
        profile_cntct2: '',
        profile_email: '',
        profile_website: '',
        profile_facebook: '',
        profile_instagram: '',
        profile_youtube: '',
        profile_twitter: '',
        profile_description: '',
        profile_photo: '',
        profile_programs: '',
        profile_trainings: '',  
        profile_participations: '',  
        profile_awards: '',  
        catg1: '',  
        catg2: '',  
        catg3: '',  
        catg4: '',  
        catg5: '',  
        premium_ind: 'N',   
        Photo1: '',   
        Photo2: '',   
        Photo3: '',   
        Photo4: '',   
        Photo5: '',   
        Photo6: '',   
        Photo7: '',   
        Photo8: '',   
        Photo9: '',   
        Photo10: '',   
        Video1: '',   
        Video2: '',   
        Brochure: '',   
        Biodata: '',   
        },
        RespArray: [],
        RespAllProfiles: [],
        RespArrayIns: [],
        show: true,
        SearchBy: '',
        SelectedPhoto: [],
        Images: [
          { text: 'Photo1', value: 'Photo1' },
          { text: 'Photo2', value: 'Photo2' },
          { text: 'Photo3', value: 'Photo3' },
          { text: 'Photo4', value: 'Photo4' },
          { text: 'Photo5', value: 'Photo5' },
          { text: 'Photo6', value: 'Photo6' },
          { text: 'Photo7', value: 'Photo7' },
          { text: 'Photo8', value: 'Photo8' },
          { text: 'Photo9', value: 'Photo9' },
          { text: 'Photo10', value: 'Photo10' }                  
        ]                 
      }
    },
    mounted()
    {
      this.ReadAllProfiles()
    },    
    methods: {
      onSubmit(event) {
      event.preventDefault()
      this.InsUserProfile()

      },
      ReadAllProfiles(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllProfiles')
        .then((resp)=>{
        this.RespAllProfiles=resp.data.AllProfiles;
      })
      },      
      InsUserProfile(){  
        const REQformData = new FormData();
        REQformData.append('profile_name', this.FormProf.profile_name);
        REQformData.append('profile_title', this.FormProf.profile_title);
        REQformData.append('profile_address', this.FormProf.profile_address);
        REQformData.append('prof_country', this.FormProf.prof_country);
        REQformData.append('prof_state', this.FormProf.prof_state);
        REQformData.append('prof_city', this.FormProf.prof_city);
        REQformData.append('profile_cntct1', this.FormProf.profile_cntct1);
        REQformData.append('profile_cntct2', this.FormProf.profile_cntct2);
        REQformData.append('profile_email', this.FormProf.profile_email);
        REQformData.append('profile_website', this.FormProf.profile_website);
        REQformData.append('profile_facebook', this.FormProf.profile_facebook);
        REQformData.append('profile_instagram', this.FormProf.profile_instagram);
        REQformData.append('profile_youtube', this.FormProf.profile_youtube);
        REQformData.append('profile_twitter', this.FormProf.profile_twitter);
        REQformData.append('profile_description', this.FormProf.profile_description);
        REQformData.append('profile_photo', this.FormProf.profile_photo);
        REQformData.append('profile_programs', this.FormProf.profile_programs);
        REQformData.append('profile_trainings', this.FormProf.profile_trainings);
        REQformData.append('profile_participations', this.FormProf.profile_participations);
        REQformData.append('profile_awards', this.FormProf.profile_awards);
        REQformData.append('catg1', this.FormProf.catg1);
        REQformData.append('catg2', this.FormProf.catg2);
        REQformData.append('catg3', this.FormProf.catg3);
        REQformData.append('catg4', this.FormProf.catg4);
        REQformData.append('catg5', this.FormProf.catg5);
        REQformData.append('premium_ind', this.FormProf.premium_ind);
        REQformData.append('Photo1', this.SelectedPhoto[0]);
        REQformData.append('Photo2', this.SelectedPhoto[1]);
        REQformData.append('Photo3', this.SelectedPhoto[2]);
        REQformData.append('Photo4', this.SelectedPhoto[3]);
        REQformData.append('Photo5', this.SelectedPhoto[4]);
        REQformData.append('Photo6', this.SelectedPhoto[5]);
        REQformData.append('Photo7', this.SelectedPhoto[6]);
        REQformData.append('Photo8', this.SelectedPhoto[7]);
        REQformData.append('Photo9', this.SelectedPhoto[8]);
        REQformData.append('Photo10',this.SelectedPhoto[9]);
        REQformData.append('Video1', this.FormProf.Video1);
        REQformData.append('Video2', this.FormProf.Video2);
        REQformData.append('Brochure', this.FormProf.Brochure);
        REQformData.append('Biodata', this.FormProf.Biodata);

        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsUserProfile',REQformData)
        .then((resp)=>{
          this.RespArrayIns=resp.data.message;
          alert('Inserted Successfully!!')
          this.ReadAllProfiles()
          this.ClearForms()
      })
      },
      DelUserProfile(seq_no){
       const InsformData = new FormData();
        InsformData.append('seq_no', seq_no);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelUserProfile', InsformData)
        .then((resp)=>{
        this.RespArray=resp.data.message;
        alert('Deleted Successfully!!')
        this.ReadAllProfiles();
       }).catch(error => console.log(error));
       },
      ClearForms(){  
        this.FormProf.profile_name = '';
        this.FormProf.profile_title = '';
        this.FormProf.profile_address = '';
        this.FormProf.prof_country = '';
        this.FormProf.prof_state = '';
        this.FormProf.prof_city = '';
        this.FormProf.profile_cntct1 = '';
        this.FormProf.profile_cntct2 = '';
        this.FormProf.profile_email = '';
        this.FormProf.profile_website = '';
        this.FormProf.profile_facebook = '';
        this.FormProf.profile_instagram = '';
        this.FormProf.profile_youtube = '';
        this.FormProf.profile_twitter = '';
        this.FormProf.profile_description = '';
        this.FormProf.profile_photo = '';
        this.FormProf.profile_programs = '';
        this.FormProf.profile_trainings = '';  
        this.FormProf.profile_participations = '';  
        this.FormProf.profile_awards = '';  
        this.FormProf.catg1 = '';  
        this.FormProf.catg2 = '';  
        this.FormProf.catg3 = '';  
        this.FormProf.catg4 = '';  
        this.FormProf.catg5 = '';  
        this.FormProf.premium_ind = '';   
        this.FormProf.Photo1 = '';   
        this.FormProf.Photo2 = '';   
        this.FormProf.Photo3 = '';   
        this.FormProf.Photo4 = '';   
        this.FormProf.Photo5 = '';   
        this.FormProf.Photo6 = '';   
        this.FormProf.Photo7 = '';   
        this.FormProf.Photo8 = '';   
        this.FormProf.Photo9 = '';   
        this.FormProf.Photo10 = '';   
        this.FormProf.Video1 = '';   
        this.FormProf.Video2 = '';   
        this.FormProf.Brochure = '';   
        this.FormProf.Biodata = '';   

},                 
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      }
    },
    computed: {
    SearchList: function(){
        return this.RespAllProfiles.filter((RespAllProfiles) => {
        return RespAllProfiles.profile_name.toLowerCase().match(this.SearchBy.toLowerCase()) && RespAllProfiles.cde_status == 'P'
      });
    },
    ListInactive: function(){
        return this.RespAllProfiles.filter((RespAllProfiles) => {
        return RespAllProfiles.cde_status != 'P'
      });
    },    
    rows() {
      return this.SearchList.length;
    },    
  }    
  }
</script>

<style scoped>
.admin-main .admin-form-login {
  padding: 40px 200px 80px 50px;    
}
</style>
